<template>
  <base-table-row
    :columns="columns"
    v-bind="item"
    class="dns-record-table-row"
    col-class="dns-record-table-row__col"
  >
    <template #type>
      <span
        v-tooltip="item.rtype"
        class="dns-record-table-row__type"
        :class="{ [`${typeColor[item.rtype_orig]}-color`]: !!typeColor[item.rtype_orig] }"
      >
        {{ item.rtype_hidden }}
      </span>
    </template>
    <template #host>
      {{ item.name }}
    </template>
    <template #value>
      <span class="dns-record-table-row__value note-text">
        {{ item.value }}
      </span>
    </template>
    <template #ttl>
      <span class="dns-record-table-row__ttl note-text">
        {{ item.ttl }}
      </span>
    </template>
    <template #info>
      <span class="dns-record-table-row__info note-text small-text">
        {{ item.info || '' }}
      </span>
    </template>
    <template v-if="contextMenu.length" #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="dns-record-table-row__popover"
      >
        <plain-button
          icon="more"
          class="dns-record-table-row__more"
          @click="togglePopoverState"
        /><template #popover>
          <domains-context-menu
            :tariff="item"
            :menu="contextMenu"
            :module-main="moduleMain"
            :tools="tools"
            class="dns-record-table-row__context-menu"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import DomainsContextMenu from './DomainsContextMenu.vue';
import popoverFix from '@/mixins/popoverFix';
import dnsRecordContextMenu from '../mixins/dnsRecordContextMenu';
import { baseColors } from '@/models/base/baseColors';
export default {
  name: 'DnsRecordTableRow',
  components: { BaseTableRow, DomainsContextMenu },
  mixins: [popoverFix, dnsRecordContextMenu],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moduleMain: 'moduleDomains.moduleDomainsDnsHost.moduleDnsRecords',
      typeColor: {
        a: baseColors.success,
        ns: baseColors.primary,
        soa: baseColors.warning,
      },
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.moduleDnsRecords.tools;
    },
    current() {
      return this.item;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.dns-record-table-row {
  &__value {
    word-break: break-all;
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dns-record-table-row {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
